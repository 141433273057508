@mixin color-utils($palette, $colors) {
  @each $color, $value in $colors {
    @if $color != 'contrast' {
      .background-#{$palette}-#{$color} {
        background: $value !important;
      }

      .color-#{$palette}-#{$color} {
        color: $value !important;
      }
    }
  }
}
