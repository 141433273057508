@import '~@angular/material/theming';
@import 'variables';
@import 'color-utils';


$custom-typography: mat-typography-config(
  $font-family: 'Muli, sans-serif'
);

@include mat-core($custom-typography);

$theme: mat-light-theme($primary-palette, $accent-palette, $warn-palette);
@include angular-material-theme($theme);

// define css classes for variations of primary & accent colors
@include color-utils('primary', map-get($theme, primary));
@include color-utils('accent', map-get($theme, accent));

.mat-dark-theme {
  $dark-theme: mat-dark-theme($dark-primary-palette, $dark-accent-palette, $dark-warn-paletted);
  @include angular-material-theme($dark-theme);
}

.background-primary {
  background: $primary !important;
}
.background-accent {
  background: $accent !important;
}
.color-primary {
  color: $primary !important;
}
.color-accent {
  color: $accent !important;
}
.color-white {
  color: #fff !important;
}
.color-warn {
  color: $warn !important;
}
